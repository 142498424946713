import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import moment from 'moment';
import cn from 'classnames'

export default function MarketHistory() {
  const recentPosition = useSelector((state: RootState) => state.user.relationships.opened_positions)

  return (
    <>
      <div className="market-history">
        <Tabs defaultActiveKey="recent-trades">
          <Tab eventKey="recent-trades" title="Recent Trades">
            <table className="table">
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Open&nbsp;at&nbsp;Price</th>
                  <th>Bid&nbsp;Amount</th>
                </tr>
              </thead>
              <tbody>
                {recentPosition && recentPosition.map((position, index) => (
                  <tr key={index}>
                    <td>{moment(position.created_at).format('YYYY-MM-DD')}</td>
                    <td className={cn(position.side === "sell" ? "red" : "green", "max-w-[140px] truncate")}>{position.open_at_price}</td>
                    <td className='max-w-[100px] truncate'>{position.bid}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
