import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import axios from 'axios';
import { DOMAIN } from '../App';
import Cookies from 'js-cookie';
import { setUser } from '../store/features/userSlice/userSlice'

export default function Login() {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const [userInformation, setUserInformation] = useState({
    email: '',
    password: ''
  })

  const [loader, setLoader] = useState(false)


  const handleUserAuthentication = () => {
    console.log('handleUserAuthentication')

    const token = Cookies.get('token')

    console.log('token', token)

    if (token) {
      setLoader(true)
      console.log('token & userID present')
      axios
        .get(`${DOMAIN}/api/self`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(async function (response) {
          setLoader(false)
          console.log('client', response)

          // TODO: set user data in redux
          dispatch(setUser(response.data))

          // navigate to dashboard
          // window.location.pathname = '/dashboard'
          navigate('/dashboard')
        })
        .catch(function (error) {
          setLoader(false)
          Cookies.remove('token')
          Cookies.remove('userID')
          // window.location.pathname = '/'
          navigate('/')
          // setLoading(false)
          console.log(error)
        })
    }
  }

  useEffect(() => {
    console.log('location', location)
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (token) {
      console.log('Token from URL:', token);
      // You can use the token as needed, e.g., set it in cookies or state
      Cookies.set('token', token);
      handleUserAuthentication()
    }
  }, [location])

  const handleFormInputChange = (event: any) => {
    setUserInformation({
      ...userInformation,
      [event.target.name]: event.target.value
    })
  }

  const handleLoginClick = (event: any) => {
    console.log('login pushed', userInformation)
    axios
      .post(`${DOMAIN}/api/login`, {
        email: userInformation.email,
        password: userInformation.password
      })
      .then(async function (response) {
        console.log(response)
        // set access token
        Cookies.set('token', response.data.meta.token)
        Cookies.set('userID', response.data.meta.token)

        console.log('user', response.data.data)

        // TODO: set user data in redux
        dispatch(setUser(response.data))

        // navigate to dashboard
        navigate('/dashboard')
        // window.location.pathname = '/dashboard'
      })
      .catch(function (error) {
        console.log(error)
      })
  }


  return (
    <>
      {!loader && (
        <div className="vh-100 d-flex justify-content-center">
          <div className="form-access my-auto">
            <div>
              <span>Sign In</span>
              <div className="form-group">
                <input
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  value={userInformation.email}
                  onChange={handleFormInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  name="password"
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  value={userInformation.password}
                  onChange={handleFormInputChange}
                  required
                />
              </div>
              <div className="text-right">
                <Link to="/reset">Forgot Password?</Link>
              </div>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="form-checkbox"
                />
                <label className="custom-control-label" htmlFor="form-checkbox">
                  Remember me
                </label>
              </div>
              <button className="btn btn-primary" onClick={handleLoginClick}>
                Sign In
              </button>
            </div>
            <h2>
              Don't have an account? <Link to="/signup">Sign up here</Link>
            </h2>
          </div>
        </div>
      )}
      {loader && (
        <div className='w-screen h-screen flex items-center justify-center'>
          <div
            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          >
            <span
              className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
            >
              Loading...
            </span>
          </div>
        </div>
      )}
    </>
  );
}
