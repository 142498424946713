import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICoin } from '../coinSlice/coinSlice'


const initialState: ICoin = {
  active: 0,
  id: 0,
  max_multiplier: 0,
  name: "",
  slug: "",
  symbol: ""
}

export const stateSlice = createSlice({
  name: 'tempState',
  initialState,
  reducers: {
    setActiveCoinID: (state, action: PayloadAction<ICoin>) => {
      return action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setActiveCoinID } = stateSlice.actions

export default stateSlice.reducer
