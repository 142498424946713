import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

export default function CurrentBalance() {

  const coins = useSelector((state: RootState) => state.coins.coins)
  const wallet = useSelector((state: RootState) => state.user.relationships.wallets)

  const balance = useSelector((state: RootState) => state.user.data.balance) 
  const margin_balance = useSelector((state: RootState) => state.user.data.margin_balance) 

  return (
    <>
      <div className="market-history mb15">
        <Tabs defaultActiveKey="recent-trades">
          <Tab eventKey="recent-trades" title="Current Balance">
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody className='!h-[118px]'>
                <tr>
                  <td>Spot Balance</td>
                  <td>{`${balance}`}&nbsp;USDT</td>
                </tr>
                <tr>
                  <td>Margin Balance</td>
                  <td>{`${margin_balance}`}&nbsp;USDT</td>
                </tr>
              </tbody>
            </table>
            <table className="table">
              <thead>
                <tr>
                  <th>Coin Name</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {coins.map((coin, index) => (
                  <tr key={index}>
                    <td>{coin.name}</td>
                    {wallet && wallet.filter((c) => c.coin_id === coin.id).length > 0 && (
                      <td>{`${wallet.filter((c) => c.coin_id === coin.id)[0].balance}`}&nbsp;{`${coin.symbol}`}</td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
