import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

import Cookies from 'js-cookie'
import { DOMAIN } from '../../App'
import { ICoin } from '../../store/features/coinSlice/coinSlice'

interface IRawCoin {
  data: ICoin
  meta: Array<any>
  relationships: Array<any>
}

export const fetchCoinsFromAPI = createAsyncThunk(
  'coins/fetchCoinsData',
  async (thunkAPI) => {
    const response = await axios.get(`${DOMAIN}/api/coins`, {
      headers: { Authorization: `Bearer ${Cookies.get('token')}` }
    })

    console.log('redux coins api', response.data)

    let coins: ICoin[] = []

    response.data.forEach((coin: IRawCoin) => {
      coins.push(coin.data)
    });

    return coins 
  }
)