import { createSlice } from '@reduxjs/toolkit'
import { fetchClosedPositionsFromAPI, fetchOpenPositionsFromAPI, fetchPositionsIndexFromAPI } from '../../../api/slices/Positions.api'

export interface IOpenedPosition {
}

export interface IClosedPosition{
}

export interface IPositionIndex{
}

export interface IPositionState{
  openPositions: IOpenedPosition[]
  closedPositions: IClosedPosition[]
  positionIndex: IPositionIndex[]
}

const initialState: IPositionState = {
  openPositions: [],
  closedPositions: [],
  positionIndex: []
}

export const positionSlice = createSlice({
  name: 'positions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOpenPositionsFromAPI.fulfilled, (state, action) => {
      state.openPositions = action.payload
    })
    builder.addCase(fetchClosedPositionsFromAPI.fulfilled, (state, action) => {
      state.closedPositions = action.payload
    })
    builder.addCase(fetchPositionsIndexFromAPI.fulfilled, (state, action) => {
      state.positionIndex = action.payload
    })
  }
})

// Action creators are generated for each case reducer function
export const { } = positionSlice.actions

export default positionSlice.reducer
