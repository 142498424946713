import { combineReducers, configureStore } from '@reduxjs/toolkit'

import userSliceReducer from './features/userSlice/userSlice'
import coinSliceReducer from './features/coinSlice/coinSlice'
import positionSliceReducer from './features/positionsSlice/positionsSlice'
import stateSliceReducer from './features/stateSlice/stateSlice'

const rootReducer = combineReducers({
  user: userSliceReducer,
  coins: coinSliceReducer,
  positions: positionSliceReducer,
  innerState: stateSliceReducer
})

export const store = configureStore({
  reducer: rootReducer
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
