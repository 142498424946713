import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IWalletElement {
  active: number
  balance: string
  coin_id: number
  created_at: string
  id: number
  trader_id: number
  updated_at: string
}

interface IOrdersElement {
  id: number
  admin_id: number
  trader_id: number
  coin_id: number
  trader_email: string
  type: "spot" | "margin"
  side: "buy" | "sell"
  symbol: string
  slug: string
  ask_at: string
  total_price: string
  quantity: string
  created_at: string
  updated_at: string
}

interface IUserState {
  data: {
    email: string
    name: string
    surname: string
    balance: string
    margin_balance: string
  }
  meta: {
    token: string | null
  }
  relationships: {
    orders: IOrdersElement[]
    positions: any[]
    opened_positions: any[]
    closed_positions: any[]
    recent_positions: any[]
    wallets: IWalletElement[]
  }
}

const initialState: IUserState = {
  data: {
    email: '',
    name: '',
    surname: '',
    balance: '',
    margin_balance: ''
  },
  meta: {
    token: null
  },
  relationships: {
    orders: [],
    positions: [],
    opened_positions: [],
    closed_positions: [],
    recent_positions: [],
    wallets: []
  }
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUserState>) => {
      return action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setUser } = userSlice.actions

export default userSlice.reducer
