import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { fetchCoinsFromAPI } from '../api/slices/Coin.api';
import moment from 'moment'

export default function HistoryOrder() {
  const orderHistory = useSelector((state: RootState) => state.user.relationships.orders)
  const reversedOrderHistory = orderHistory && orderHistory.slice().reverse()

  const positions = useSelector((state: RootState) => state.user.relationships.positions)
  const reversedPositions = positions && positions.slice().reverse()

  const openedPositions = useSelector((state: RootState) => state.user.relationships.opened_positions)
  const reversedOpenedPositions = openedPositions && openedPositions.slice().reverse()

  const closedPositions = useSelector((state: RootState) => state.user.relationships.closed_positions)
  const reversedClosedPositions = closedPositions && closedPositions.slice().reverse()

  return (
    <>
      <div className="market-history market-order mt15">
        <Tabs defaultActiveKey="spot-history">
          <Tab eventKey="spot-history" title="Spot history">
            <ul className="d-flex justify-content-between market-order-item">
              <li>Time</li>
              <li>All pairs</li>
              <li>All Types</li>
              <li>Buy/Sell</li>
              <li>Price</li>
              <li>Amount</li>
              <li>Executed</li>
              <li>Unexecuted</li>
            </ul>
            {!orderHistory || orderHistory.length < 1 && (
              <span className="no-data">
                <i className="icon ion-md-document"></i>
                No data
              </span>
            )}
            {reversedOrderHistory && reversedOrderHistory.length > 0 && reversedOrderHistory.slice(0, 10).map((order, index) => (
              <ul className="d-flex justify-content-between market-order-item" key={index}>
                <li>{moment(order.created_at).format('YYYY-MM-DD')}</li>
                <li>{`${order.symbol}/USDT`}</li>
                <li>{order.type}</li>
                <li>{order.side}</li>
                <li>{order.ask_at}</li>
                <li>{order.quantity}</li>
                <li>null</li>
                <li>null</li>
              </ul>
            ))}
          </Tab>
          <Tab eventKey="margin-history" title="Margin History">
            <ul className="d-flex justify-content-between market-order-item">
              <li>Time</li>
              <li>Type</li>
              <li>Buy/Sell</li>
              <li>Status</li>
              <li>Bid</li>
              <li>Stop Loss</li>
              <li>Take Profit</li>
              <li>Open at Price</li>
              <li>Close at Price</li>
              <li>Result Amount</li>
            </ul>
            {!positions || positions.length < 1 && (
              <span className="no-data">
                <i className="icon ion-md-document"></i>
                No data
              </span>
            )}
            {reversedPositions && reversedPositions.length > 0 && reversedPositions.slice(0, 10).map((order, index) => (
              <ul className="d-flex justify-content-between market-order-item" key={index}>
                <li>{moment(order.created_at).format('YYYY-MM-DD')}</li>
                <li>{order.type}</li>
                <li>{order.side}</li>
                <li>{order.status}</li>
                <li className="w-[80px] truncate">{order.bid}</li>
                <li className="w-[80px] truncate">{order.stop_loss}</li>
                <li className="w-[80px] truncate">{order.take_profit}</li>
                <li className="w-[80px] truncate">{order.open_at_price}</li>
                <li className="w-[80px] truncate">{order.close_at_price}</li>
                <li className="w-[80px] truncate">{order.result_amount}</li>
              </ul>
            ))}
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
