import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import Markets from './markets';
import Profile from './profile';
import Wallet from './wallet';
import Settings from './settings';
import Reset from './reset';
import OtpVerify from './otp-verify';
import OtpNumber from './otp-number';
import Lock from './lock';
import TermsAndConditions from './terms-and-conditions';
import NewsDetails from './news-details';
import Signup from './signup';
import Notfound from './notfound';
import Login from './login'

import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import axios from 'axios';
import { DOMAIN } from '../App';
import { setUser } from '../store/features/userSlice/userSlice';

import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from 'react-router-dom'
import { Exchange } from './exchange';

export default function Index() {
  const loggedIn = Cookies.get('token') !== undefined && Cookies.get('token') !== null

  return (
    <>
      <Router>
        <Routes>
          <Route path="/dashboard" element={!loggedIn ? <Navigate to="/" /> : (
            <Layout>
              <Exchange />
            </Layout>
          )} />
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
      </Router>
    </>
  );
}