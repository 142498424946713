import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

import Cookies from 'js-cookie'
import { DOMAIN } from '../../App'
import { IClosedPosition, IOpenedPosition, IPositionIndex } from '../../store/features/positionsSlice/positionsSlice'

export const fetchOpenPositionsFromAPI = createAsyncThunk(
  'positions/fetchOpenPositionsData',
  async (thunkAPI) => {
    const response = await axios.get(`${DOMAIN}/api/positions/opened`, {
      headers: { Authorization: `Bearer ${Cookies.get('token')}` }
    })

    console.log('redux openPositions api', response.data)

    let openedPositions: IOpenedPosition[] = []

    return openedPositions
  }
)

export const fetchClosedPositionsFromAPI = createAsyncThunk(
  'positions/fetchClosedPositionsData',
  async (thunkAPI) => {
    const response = await axios.get(`${DOMAIN}/api/positions/closed`, {
      headers: { Authorization: `Bearer ${Cookies.get('token')}` }
    })

    console.log('redux closedPositions api', response.data)

    let closedPositions: IClosedPosition[] = []

    return closedPositions
  }
)

export const fetchPositionsIndexFromAPI = createAsyncThunk(
  'positions/fetchPositionsIndexData',
  async (thunkAPI) => {
    const response = await axios.get(`${DOMAIN}/api/positions`, {
      headers: { Authorization: `Bearer ${Cookies.get('token')}` }
    })

    console.log('redux positionsIndex api', response.data)

    let positionsIndex: IPositionIndex[] = []

    return positionsIndex 
  }
)