import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchCoinsFromAPI } from '../../../api/slices/Coin.api'
import { fetchMarketFromAPI } from '../../../api/slices/Market.api'

export interface ICoin {
  active: number
  id: number
  max_multiplier: number
  name: string
  slug: string
  symbol: string
}

export interface IMarket {
name: string
quote: {
  USD: {
    price: number
    percent_change_24h: number
  }
}
slug: string
symbol: string
tags: any[]
total_supply: number
}

interface IState {
  coins: ICoin[]
  markets: IMarket[]
}

const initialState: IState = {
  coins: [],
  markets: []
}

export const coinSlice = createSlice({
  name: 'coins',
  initialState,
  reducers: {
    setCoins: (state, action: PayloadAction<ICoin[]>) => {
      state.coins = action.payload
    },
    setMarkets: (state, action: PayloadAction<IMarket[]>) => {
      state.markets = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCoinsFromAPI.fulfilled, (state, action) => {
      state.coins = action.payload
    });
    builder.addCase(fetchMarketFromAPI.fulfilled, (state, action) => {
      state.markets = action.payload
    });
  }
})

// Action creators are generated for each case reducer function
export const { setCoins } = coinSlice.actions

export default coinSlice.reducer
