import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

import Cookies from 'js-cookie'
import { DOMAIN } from '../../App'
import { IMarket } from '../../store/features/coinSlice/coinSlice'

export const fetchMarketFromAPI = createAsyncThunk(
  'coins/fetchMarketData',
  async (thunkAPI) => {
    const response = await axios.get(`${DOMAIN}/api/market/coins`, {
      headers: { Authorization: `Bearer ${Cookies.get('token')}` }
    })

    console.log('redux market api', response.data)

    const marketObject: { [key: number]: IMarket } = response.data

    const markets: IMarket[] = Object.values(marketObject)

    console.log('markets', markets)

    return markets
  }
)