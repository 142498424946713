import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { DOMAIN } from '../App';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { setUser } from '../store/features/userSlice/userSlice';

export default function MarketTrade() {
  const market = useSelector((state: RootState) => state.coins.markets)

  const user = useSelector((state: RootState) => state.user)

  const [tradeInformation, setTradeInformation] = useState({
    buy_amount: 0,
    sell_amount: 0
  })

  const [marginTradeInfo, setMarginTradeInfo] = useState({
    coin_id: 0,
    side: "sell",
    multiplier: 0,
    bid_buy: 0,
    stop_loss: 0,
    take_profit: 0,
    bid_sell: 0,
    stop_loss_sell: 0,
    take_profit_sell: 0,
    multiplier_sell: 0
  })

  const activeCoin = useSelector((state: RootState) => state.innerState)

  const dispatch = useDispatch<AppDispatch>()

  const updateUserDataAfterTrade = () => {
    console.log('Updating user balance')

    axios
    .get(`${DOMAIN}/api/self`, {
      headers: { Authorization: `Bearer ${Cookies.get('token')}` }
    })
    .then(async function (response) {
      console.log('client', response)
      // TODO: set user data in redux
      dispatch(setUser(response.data))
    })
    .catch(function (error) {
      console.log(error)
    })
  }

  const handleFormInputChange = (event: any) => {
    setTradeInformation({
      ...tradeInformation,
      [event.target.name]: event.target.value
    })
  }

  const handleMarginFormInputChange = (event: any) => {
    setMarginTradeInfo({
      ...marginTradeInfo,
      [event.target.name]: event.target.value
    })
  }

  const handleSpotBuyUSDT = (event: any) => {
    setTransferMessage('...')
    event.preventDefault();
    console.log('Spot Buy');

    // dialog
    handleOpen()
    setTradeInformation({
      ...tradeInformation,
      buy_amount: 0
    })

    axios
      .post(`${DOMAIN}/api/spot/trade/by/usdt`, {
        coin_id: activeCoin.id,
        side: "buy",
        amount: tradeInformation.buy_amount
      }, {
        headers: { Authorization: `Bearer ${Cookies.get('token')}` }
      })
      .then(async function (response) {
        console.log(response)

        setTransferMessage('Trade was successful!')
        updateUserDataAfterTrade()
      })
      .catch(function (error) {
        console.log(error)

        setTransferMessage(error.response.data.message)
      })
  }

  const handleSpotSellUSDT = (event: any) => {
    setTransferMessage('...')
    event.preventDefault();
    console.log('Spot Sell');

    handleOpen()
    setTradeInformation({
      ...tradeInformation,
      sell_amount: 0
    })

    axios
      .post(`${DOMAIN}/api/spot/trade/by/usdt`, {
        coin_id: activeCoin.id,
        side: "sell",
        amount: tradeInformation.sell_amount
      }, {
        headers: { Authorization: `Bearer ${Cookies.get('token')}` }
      })
      .then(async function (response) {
        console.log(response)

        setTransferMessage('Trade was successful!')
        updateUserDataAfterTrade()
      })
      .catch(function (error) {
        console.log(error)

        setTransferMessage(error.response.data.message)
      })
  }

  const handleSpotBuy = (event: any) => {
    setTransferMessage('...')
    event.preventDefault();
    console.log('Spot Buy');

    // dialog
    handleOpen()
    setTradeInformation({
      ...tradeInformation,
      buy_amount: 0
    })

    axios
      .post(`${DOMAIN}/api/spot/trade/by/coin`, {
        coin_id: activeCoin.id,
        side: "buy",
        quantity: tradeInformation.buy_amount
      }, {
        headers: { Authorization: `Bearer ${Cookies.get('token')}` }
      })
      .then(async function (response) {
        console.log(response)

        setTransferMessage('Trade was successful!')
        updateUserDataAfterTrade()
      })
      .catch(function (error) {
        console.log(error)

        setTransferMessage(error.response.data.message)
      })
  }

  const handleSpotSell = (event: any) => {
    setTransferMessage('...')
    event.preventDefault();
    console.log('Spot Sell');

    handleOpen()
    setTradeInformation({
      ...tradeInformation,
      sell_amount: 0
    })

    axios
      .post(`${DOMAIN}/api/spot/trade/by/coin`, {
        coin_id: activeCoin.id,
        side: "sell",
        quantity: tradeInformation.sell_amount
      }, {
        headers: { Authorization: `Bearer ${Cookies.get('token')}` }
      })
      .then(async function (response) {
        console.log(response)

        setTransferMessage('Trade was successful!')
        updateUserDataAfterTrade()
      })
      .catch(function (error) {
        console.log(error)

        setTransferMessage(error.response.data.message)
      })
  }

  const handleMarginBuy = (event: any) => {
    setTransferMessage('...')
    event.preventDefault();
    console.log('Margin Buy');

    // dialog
    handleOpen()
    setMarginTradeInfo({
      coin_id: 0,
      side: "buy",
      multiplier: 0,
      bid_sell: 0,
      bid_buy: 0,
      stop_loss: 0,
      take_profit:0,
      stop_loss_sell: 0,
      take_profit_sell: 0,
      multiplier_sell: 0
    })

    axios
      .post(`${DOMAIN}/api/positions/open`, {
        coin_id: activeCoin.id,
        side: "buy",
        multiplier: marginTradeInfo.multiplier,
        bid: marginTradeInfo.bid_buy,
        stop_loss: marginTradeInfo.stop_loss,
        take_profit: marginTradeInfo.take_profit
      }, {
        headers: { Authorization: `Bearer ${Cookies.get('token')}` }
      })
      .then(async function (response) {
        console.log(response)

        setTransferMessage('Trade was successful!')
        updateUserDataAfterTrade()
      })
      .catch(function (error) {
        console.log(error)

        setTransferMessage(error.response.data.message)
      })
  }

  const handleMarginSell = (event: any) => {
    setTransferMessage('...')
    event.preventDefault();
    console.log('Margin Sell');

    // dialog
    handleOpen()
    setMarginTradeInfo({
      coin_id: 0,
      side: "sell",
      multiplier: 0,
      bid_buy: 0,
      bid_sell: 0,
      stop_loss: 0,
      take_profit:0,
      stop_loss_sell: 0,
      take_profit_sell: 0,
      multiplier_sell: 0
    })

    axios
      .post(`${DOMAIN}/api/positions/open`, {
        coin_id: activeCoin.id,
        side: "sell",
        multiplier: marginTradeInfo.multiplier_sell,
        bid: marginTradeInfo.bid_sell,
        stop_loss: marginTradeInfo.stop_loss_sell,
        take_profit: marginTradeInfo.take_profit_sell
      }, {
        headers: { Authorization: `Bearer ${Cookies.get('token')}` }
      })
      .then(async function (response) {
        console.log(response)

        setTransferMessage('Trade was successful!')
        updateUserDataAfterTrade()
      })
      .catch(function (error) {
        console.log(error)

        setTransferMessage(error.response.data.message)
      })
  }

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(!open)

  const [transferMessage, setTransferMessage] = useState('')

  const [buyDisabled, setBuyDisabled] = useState(false)
  const [sellDisabled, setSellDisabled] = useState(false)

  useEffect(() => {
    if (tradeInformation.buy_amount === 0) {
      setBuyDisabled(true)
    } else {
      setBuyDisabled(false)
    }
  }, [tradeInformation.buy_amount])

  useEffect(() => {
    if (tradeInformation.sell_amount === 0) {
      setSellDisabled(true)
    } else {
      setSellDisabled(false)
    }
  }, [tradeInformation.sell_amount])

  return (
    <>
      <div className="market-trade">
        <Tabs defaultActiveKey="spot-usdt">
          <Tab eventKey="spot-usdt" title="Spot Trade (USDT)">
            {activeCoin.symbol === '' && (
              <div className='flex flex-col gap-2 items-center justify-center h-full my-12'>
                <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 -960 960 960" width="36px" fill="#AEB8C1"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <p className='!text-sm text-[#AEB8C1]'>Please select a coin to trade</p>
              </div>
            )}
            {activeCoin.symbol !== '' && (
              <div className="d-flex justify-content-between">
                <div className="market-trade-buy">
                  <form action="#">
                    <label htmlFor="buy_amount" className='mt-2'>Amount in USDT</label>
                    <div className="input-group">
                      <input
                        name="buy_amount"
                        value={tradeInformation.buy_amount}
                        onChange={handleFormInputChange}
                        type="number"
                        className="form-control"
                        placeholder="Price"
                        required
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          {activeCoin.symbol ? activeCoin.symbol : 'Please Select Coin'}
                        </span>
                      </div>
                    </div>
                    {activeCoin.symbol !== '' && (
                      <ul className="market-trade-list">
                        <li>
                          <div className='py-0 px-2 rounded bg-[#F0F2F6] hover:bg-[#27A69A] hover:text-[#fff] hover:cursor-pointer' onClick={() => {
                            setTradeInformation({
                              ...tradeInformation,
                              buy_amount: 0.25 * (parseFloat(user.data.balance)),
                            });
                          }}>
                            25%
                          </div>
                        </li>
                        <li>
                          <div className='py-0 px-2 rounded bg-[#F0F2F6] hover:bg-[#27A69A] hover:text-[#fff] hover:cursor-pointer' onClick={() => {
                            setTradeInformation({
                              ...tradeInformation,
                              buy_amount: 0.50 * (parseFloat(user.data.balance)),
                            });
                          }}>
                            50%
                          </div>
                        </li>
                        <li>
                          <div className='py-0 px-2 rounded bg-[#F0F2F6] hover:bg-[#27A69A] hover:text-[#fff] hover:cursor-pointer' onClick={() => {
                            setTradeInformation({
                              ...tradeInformation,
                              buy_amount: 0.75 * (parseFloat(user.data.balance)),
                            });
                          }}>
                            75%
                          </div>
                        </li>
                        <li>
                          <div className='py-0 px-2 rounded bg-[#F0F2F6] hover:bg-[#27A69A] hover:text-[#fff] hover:cursor-pointer' onClick={() => {
                            setTradeInformation({
                              ...tradeInformation,
                              buy_amount: (parseFloat(user.data.balance)),
                            });
                          }}>
                            100%
                          </div>
                        </li>
                      </ul>
                    )}
                    <p>
                      Available: <span>
                        {(() => {
                          const marketData = market.find((c) => c.symbol === activeCoin.symbol);
                          if (marketData) {
                            const priceOfBitcoin = marketData.quote.USD.price;
                            const amountOfBitcoin = tradeInformation.buy_amount / priceOfBitcoin;
                            return `${amountOfBitcoin.toFixed(5)} ${activeCoin.symbol} = ${tradeInformation.buy_amount} USDT`;
                          }
                          return `0 ${activeCoin.symbol}  = 0 USDT`;
                        })()}
                      </span>
                    </p>
                    <button className="btn buy" onClick={handleSpotBuyUSDT} disabled={buyDisabled}>
                      Buy
                    </button>
                  </form>
                </div>
                <div className="market-trade-sell">
                  <form action="#">
                    <label htmlFor="buy_amount" className='mt-2'>Amount in USDT</label>
                    <div className="input-group">
                      <input
                        name="sell_amount"
                        value={tradeInformation.sell_amount}
                        onChange={handleFormInputChange}
                        type="number"
                        className="form-control"
                        placeholder="Price"
                        required
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          {activeCoin.symbol ? activeCoin.symbol : 'Please Select Coin'}
                        </span>
                      </div>
                    </div>
                    {activeCoin.symbol !== '' && (
                      <ul className="market-trade-list">
                        <li>
                          <div className='py-0 px-2 rounded bg-[#F0F2F6] hover:bg-[#27A69A] hover:text-[#fff] hover:cursor-pointer' onClick={() => {
                            setTradeInformation({
                              ...tradeInformation,
                              sell_amount: 0.25 * (parseFloat(user.relationships.wallets.filter((w) => w.coin_id === activeCoin.id)[0].balance) * market.find((c) => c.symbol === activeCoin.symbol)!.quote.USD.price),
                            });
                          }}>
                            25%
                          </div>
                        </li>
                        <li>
                          <div className='py-0 px-2 rounded bg-[#F0F2F6] hover:bg-[#27A69A] hover:text-[#fff] hover:cursor-pointer' onClick={() => {
                            setTradeInformation({
                              ...tradeInformation,
                              sell_amount: 0.50 * (parseFloat(user.relationships.wallets.filter((w) => w.coin_id === activeCoin.id)[0].balance) * market.find((c) => c.symbol === activeCoin.symbol)!.quote.USD.price),
                            });
                          }}>
                            50%
                          </div>
                        </li>
                        <li>
                          <div className='py-0 px-2 rounded bg-[#F0F2F6] hover:bg-[#27A69A] hover:text-[#fff] hover:cursor-pointer' onClick={() => {
                            setTradeInformation({
                              ...tradeInformation,
                              sell_amount: 0.75 * (parseFloat(user.relationships.wallets.filter((w) => w.coin_id === activeCoin.id)[0].balance) * market.find((c) => c.symbol === activeCoin.symbol)!.quote.USD.price),
                            });
                          }}>
                            75%
                          </div>
                        </li>
                        <li>
                          <div className='py-0 px-2 rounded bg-[#F0F2F6] hover:bg-[#27A69A] hover:text-[#fff] hover:cursor-pointer' onClick={() => {
                            setTradeInformation({
                              ...tradeInformation,
                              sell_amount: (parseFloat(user.relationships.wallets.filter((w) => w.coin_id === activeCoin.id)[0].balance) * market.find((c) => c.symbol === activeCoin.symbol)!.quote.USD.price),
                            });
                          }}>
                            100%
                          </div>
                        </li>
                      </ul>
                    )}
                    <p>
                      Available: <span>
                        {(() => {
                          const marketData = market.find((c) => c.symbol === activeCoin.symbol);
                          if (marketData) {
                            const priceOfBitcoin = marketData.quote.USD.price;
                            const amountOfBitcoin = tradeInformation.sell_amount / priceOfBitcoin;
                            return `${amountOfBitcoin.toFixed(5)} ${activeCoin.symbol} = ${tradeInformation.sell_amount} USDT`;
                          }
                          return `0 ${activeCoin.symbol}  = 0 USDT`;
                        })()}
                      </span>
                    </p>
                    <button className="btn sell" onClick={handleSpotSellUSDT} disabled={sellDisabled}>Sell</button>
                  </form>
                </div>
              </div>
            )}
          </Tab>
          <Tab eventKey="spot-coin" title="Spot Trade (Coin)">
            {activeCoin.symbol === '' && (
              <div className='flex flex-col gap-2 items-center justify-center h-full my-12'>
                <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 -960 960 960" width="36px" fill="#AEB8C1"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <p className='!text-sm text-[#AEB8C1]'>Please select a coin to trade</p>
              </div>
            )}
            {activeCoin.symbol !== '' && (
              <div className="d-flex justify-content-between">
                <div className="market-trade-buy">
                  <form action="#">
                    <label htmlFor="buy_amount" className='mt-2'>Amount in Coin</label>
                    <div className="input-group">
                      <input
                        name="buy_amount"
                        value={tradeInformation.buy_amount}
                        onChange={handleFormInputChange}
                        type="number"
                        className="form-control"
                        placeholder="Price"
                        required
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          {activeCoin.symbol ? activeCoin.symbol : 'Please Select Coin'}
                        </span>
                      </div>
                    </div>
                    {activeCoin.symbol !== '' && (
                      <ul className="market-trade-list">
                        <li>
                          <div className='py-0 px-2 rounded bg-[#F0F2F6] hover:bg-[#27A69A] hover:text-[#fff] hover:cursor-pointer' onClick={() => {
                            setTradeInformation({
                              ...tradeInformation,
                              buy_amount: (parseFloat(user.data.balance) / (market.find((c) => c.symbol === activeCoin.symbol)!.quote.USD.price)) * 0.25,
                            });
                          }}>
                            25%
                          </div>
                        </li>
                        <li>
                          <div className='py-0 px-2 rounded bg-[#F0F2F6] hover:bg-[#27A69A] hover:text-[#fff] hover:cursor-pointer' onClick={() => {
                            setTradeInformation({
                              ...tradeInformation,
                              buy_amount: (parseFloat(user.data.balance) / (market.find((c) => c.symbol === activeCoin.symbol)!.quote.USD.price)) * 0.50,
                            });
                          }}>
                            50%
                          </div>
                        </li>
                        <li>
                          <div className='py-0 px-2 rounded bg-[#F0F2F6] hover:bg-[#27A69A] hover:text-[#fff] hover:cursor-pointer' onClick={() => {
                            setTradeInformation({
                              ...tradeInformation,
                              buy_amount: (parseFloat(user.data.balance) / (market.find((c) => c.symbol === activeCoin.symbol)!.quote.USD.price)) * 0.75,
                            });
                          }}>
                            75%
                          </div>
                        </li>
                        <li>
                          <div className='py-0 px-2 rounded bg-[#F0F2F6] hover:bg-[#27A69A] hover:text-[#fff] hover:cursor-pointer' onClick={() => {
                            setTradeInformation({
                              ...tradeInformation,
                              buy_amount: (parseFloat(user.data.balance) / (market.find((c) => c.symbol === activeCoin.symbol)!.quote.USD.price)),
                            });
                          }}>
                            100%
                          </div>
                        </li>
                      </ul>
                    )}
                    <p>
                      Available: <span>
                        {(() => {
                          const marketData = market.find((c) => c.symbol === activeCoin.symbol);
                          if (marketData) {
                            const priceOfBitcoin = marketData.quote.USD.price;
                            const amountOfUSDT = tradeInformation.buy_amount * priceOfBitcoin;
                            return `${tradeInformation.buy_amount} ${activeCoin.symbol} = ${amountOfUSDT.toFixed(2)} USDT`;
                          }
                          return `0 ${activeCoin.symbol}  = 0 USDT`;
                        })()}
                      </span>
                    </p>
                    <button className="btn buy" onClick={handleSpotBuy} disabled={buyDisabled}>
                      Buy
                    </button>
                  </form>
                </div>
                <div className="market-trade-sell">
                  <form action="#">
                    <label htmlFor="buy_amount" className='mt-2'>Amount in Coin</label>
                    <div className="input-group">
                      <input
                        name="sell_amount"
                        value={tradeInformation.sell_amount}
                        onChange={handleFormInputChange}
                        type="number"
                        className="form-control"
                        placeholder="Price"
                        required
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          {activeCoin.symbol ? activeCoin.symbol : 'Please Select Coin'}
                        </span>
                      </div>
                    </div>
                    {activeCoin.symbol !== '' && (
                      <ul className="market-trade-list">
                        <li>
                          <div className='py-0 px-2 rounded bg-[#F0F2F6] hover:bg-[#27A69A] hover:text-[#fff] hover:cursor-pointer' onClick={() => {
                            setTradeInformation({
                              ...tradeInformation,
                              sell_amount: 0.25 * (parseFloat(user.relationships.wallets.filter((w) => w.coin_id === activeCoin.id)[0].balance)),
                            });
                          }}>
                            25%
                          </div>
                        </li>
                        <li>
                          <div className='py-0 px-2 rounded bg-[#F0F2F6] hover:bg-[#27A69A] hover:text-[#fff] hover:cursor-pointer' onClick={() => {
                            setTradeInformation({
                              ...tradeInformation,
                              sell_amount: 0.50 * (parseFloat(user.relationships.wallets.filter((w) => w.coin_id === activeCoin.id)[0].balance)),
                            });
                          }}>
                            50%
                          </div>
                        </li>
                        <li>
                          <div className='py-0 px-2 rounded bg-[#F0F2F6] hover:bg-[#27A69A] hover:text-[#fff] hover:cursor-pointer' onClick={() => {
                            setTradeInformation({
                              ...tradeInformation,
                              sell_amount: 0.75 * (parseFloat(user.relationships.wallets.filter((w) => w.coin_id === activeCoin.id)[0].balance)),
                            });
                          }}>
                            75%
                          </div>
                        </li>
                        <li>
                          <div className='py-0 px-2 rounded bg-[#F0F2F6] hover:bg-[#27A69A] hover:text-[#fff] hover:cursor-pointer' onClick={() => {
                            setTradeInformation({
                              ...tradeInformation,
                              sell_amount: (parseFloat(user.relationships.wallets.filter((w) => w.coin_id === activeCoin.id)[0].balance)),
                            });
                          }}>
                            100%
                          </div>
                        </li>
                      </ul>
                    )}
                    <p>
                      Available: <span>
                        {(() => {
                          const marketData = market.find((c) => c.symbol === activeCoin.symbol);
                          if (marketData) {
                            const priceOfBitcoin = marketData.quote.USD.price;
                            const amountOfUSDT = tradeInformation.sell_amount * priceOfBitcoin;
                            return `${tradeInformation.sell_amount} ${activeCoin.symbol} = ${amountOfUSDT.toFixed(2)} USDT`;
                          }
                          return `0 ${activeCoin.symbol}  = 0 USDT`;
                        })()}
                      </span>
                    </p>
                    <button className="btn sell" onClick={handleSpotSell} disabled={sellDisabled}>Sell</button>
                  </form>
                </div>
              </div>
            )}
          </Tab>
          <Tab eventKey="margin" title="Margin Trade">
            {activeCoin.symbol === '' && (
              <div className='flex flex-col gap-2 items-center justify-center h-full my-12'>
                <svg xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 -960 960 960" width="36px" fill="#AEB8C1"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                <p className='!text-sm text-[#AEB8C1]'>Please select a coin to trade</p>
              </div>
            )}
            {activeCoin.symbol !== '' && (
              <div className="d-flex justify-content-between">
                <div className="market-trade-buy">
                  <form action="#">
                    <label htmlFor="bid">Bid on Coin in USDT</label>
                    <div className="input-group">
                      <input
                        name="bid_buy"
                        value={marginTradeInfo.bid_buy}
                        onChange={handleMarginFormInputChange}
                        type="number"
                        className="form-control"
                        placeholder="Bid"
                        required
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          {activeCoin.symbol ? activeCoin.symbol : 'Please Select Coin'}
                        </span>
                      </div>
                    </div>
                    <div className='flex gap-4 items-center'>
                      <div>
                        <label htmlFor="stop_loss">Stop Loss</label>
                        <input
                          name="stop_loss"
                          value={marginTradeInfo.stop_loss}
                          onChange={handleMarginFormInputChange}
                          type="number"
                          className="form-control"
                          placeholder="0.00"
                          required
                        />
                      </div>
                      <div>
                        <label htmlFor="take_profit">Take Profit</label>
                        <input
                          name="take_profit"
                          value={marginTradeInfo.take_profit}
                          onChange={handleMarginFormInputChange}
                          type="number"
                          className="form-control"
                          placeholder="0.00"
                          required
                        />
                      </div>
                    </div>
                    <label htmlFor="buy_amount" className='mt-2'>Multiplier</label>
                    <input
                      name="multiplier"
                      value={marginTradeInfo.multiplier}
                      onChange={handleMarginFormInputChange}
                      type="number"
                      className="form-control"
                      placeholder="Bid"
                      required
                    />
                    <button className="btn buy" onClick={handleMarginBuy} disabled={marginTradeInfo.bid_buy === 0}>
                      Buy
                    </button>
                  </form>
                </div>
                <div className="market-trade-sell">
                  <form action="#">
                    <label htmlFor="buy_amount">Bid on Coin in USDT</label>
                    <div className="input-group">
                      <input
                        name="bid_sell"
                        value={marginTradeInfo.bid_sell}
                        onChange={handleMarginFormInputChange}
                        type="number"
                        className="form-control"
                        placeholder="Bid"
                        required
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          {activeCoin.symbol ? activeCoin.symbol : 'Please Select Coin'}
                        </span>
                      </div>
                    </div>
                    <div className='flex gap-4 items-center'>
                      <div>
                        <label htmlFor="buy_amount">Stop Loss</label>
                        <input
                          name="stop_loss_sell"
                          value={marginTradeInfo.stop_loss_sell}
                          onChange={handleMarginFormInputChange}
                          type="number"
                          className="form-control"
                          placeholder="Bid"
                          required
                        />
                      </div>
                      <div>
                        <label htmlFor="buy_amount">Take Profit</label>
                        <input
                          name="take_profit_sell"
                          value={marginTradeInfo.take_profit_sell}
                          onChange={handleMarginFormInputChange}
                          type="number"
                          className="form-control"
                          placeholder="Bid"
                          required
                        />
                      </div>
                    </div>
                    <label htmlFor="buy_amount" className='mt-2'>Multiplier</label>
                    <input
                      name="multiplier_sell"
                      value={marginTradeInfo.multiplier_sell}
                      onChange={handleMarginFormInputChange}
                      type="number"
                      className="form-control"
                      placeholder="Bid"
                      required
                    />
                    <button className="btn sell" onClick={handleMarginSell} disabled={marginTradeInfo.bid_sell === 0}>Sell</button>
                  </form>
                </div>
              </div>
            )}
          </Tab>
        </Tabs>
      </div>
      {open && (
        <div className="z-50 popup fixed inset-0 w-full bg-[#000]/15 flex items-center justify-center">
          <div className="bg-[#fff] rounded-[8px] p-4">
            <p className='text-[28px] font-medium'>Message</p>
            <p className='text-base '>{transferMessage}</p>
            <button className='mt-8 btn w-full flex items-center justify-center bg-[#0567EA] text-[#fff] hover:bg-[#0567EA]/90' onClick={handleOpen}>Close</button>
          </div>
        </div>
      )}
    </>
  );
}
